import React, { useEffect, useState } from "react";
import TopHeader from "../../../component/TopHeader";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Reject from "./reject";
import Request from "./request";
import { useLazyDriverRequestQuery } from "../../../constants/redux/api/api";

const DriverRequest = () => {
  const [reject, setReject] = useState([]);
  const [request, setRequest] = useState([]);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");

  const [requestListApi] = useLazyDriverRequestQuery();

  const getList = () => {
    let params = "";
    if (from && to) {
      params =`?start_date=${from}&end_date=${to}`;
    }
    requestListApi(params)
      .unwrap()
      .then((res) => {
        setRequest(res?.request_drivers);
        setReject(res?.reject_drivers)
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getList();
  }, [from,to]);

  return (
    <aside>
      <TopHeader type="driver-request" />
      <article className="p-3">
        <Tabs
          defaultActiveKey="Detail-View"
          id="uncontrolled-tab-example"
          className="mb-3 tab-appoinment"
        >
          <Tab eventKey="Detail-View" title="Request List">
            <Request from={from} to={to} setFrom={setFrom} setTo={setTo} request={request}/>
          </Tab>
          <Tab eventKey="Detail" title="Rejected List">
            <Reject from={from} to={to} setFrom={setFrom} setTo={setTo} reject={reject}/>
          </Tab>
        </Tabs>
      </article>
    </aside>
  );
};

export default DriverRequest;
