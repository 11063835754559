export const BASE_URL = "https://testingscript.mywheely.in/crmapi/"; // LIVE URL
// export const BASE_URL = "http://192.168.1.30/wheely-scrpit/public/crmapi/"; // LOCAL URL

export const URL = {
  // LOGIN
  LOGIN: "admin/login",
  LOGOUT: "admin/logout",
  CHANGE_PASSWORD: "admin-change-password",

  // DASHBOARD

  DASHBOARD: "counts",
  DASHBOARD_PIE: "count-percentage",
  DASHBOARD_REVENUE: "revenue-graph",
  DASHBOARD_PAY_TAX: "pay-tax-graph",

  // ROLES
  ROLE: "role",
  ROLE_MODULE: "modules",

  // ADMIN
  ADMIN: "admins",
  ADMIN_STATUS: "admin",
  ADMIN_ROLES: "active-roles",

  // SUBSCRIPTIONS
  SUBSCRIPTION_PAYMENT: "subscription-payment-details",

  //CAR TYPE

  CARTYPE: "car-types",
  CARTYPE_STS: "car-type/",

  // ZONES
  ZONE: "zone",
  IMPORT_ZONE: "import-zone",
  EXAMPLE_ZONE: "export-sample-zone",

  // FARE
  FARE: "price",
  FARE_ESTIMATION: "ride-fare-detail",

  // FARE -  PEAK
  PEAK: "peak-charge",

  // FARE - OTHER CHARGES
  OTHERCHARGES: "other-charge",

  // SETTING

  SETTING: "setting",

  // DRIVER REQUEST

  DRIVER_REQUSET: "driver/request-rejects",
  DRIVER_REQUSET_REJECT: "driver/",
  PROOF_ACCEPT: "kyc/approve-reject",
  // DRIVER_VIEW:"driver/",
  // DRIVER

  DRIVERS: "driver-list",
  DRIVER_STATUS: "driver-status/",
  DRIVER_VIEW: "driver-profile/",
  DRIVER_RIDE_HISTORY: "driver-ride-history",
  DRIVER_RIDE_HISTORY_VIEW: "driver-ride-history-view",
  DRIVER_SUBSCRIPTION_DATE: "change-subscription-end-date",

  // USERS
  USERS: "user-list",
  USERS_VIEW: "user-view/",
  USER_STATUS: "user-status/",
  USER_RIDE_HISTORY: "user-ride-history/",
  // USER_RIDE_HISTORY_VIEW: "ride-history-view/",
  USER_RIDE_HISTORY_VIEW: "user-ride-history-view/",

  // HELP & SUPPORT
  HELP_SUPPORT_PENDING: "supports-raised",
  HELP_SUPPORT_RESOLVED: "supports-resolved ",
  REPLY_SUPPORT: "support-reply",
  HELP_SUPPORT_PENDING:"supports-list",

  // OFFLINE BOOKING
  OFFLINE_BOOKING: "offline-prebooking",
  OFFLINE_FIRST_SEARCH: "offline-first-search",
  OFFLINE_USER_LIST: "user-list",
  OFFLINE_BOOKING_LIST: "offline-booking-list",
  OFFLINE_SECOND_SEARCH: "offline-second-search",
  OFFLINE_BOOKING_STATUS: "search-ride-status",
  CANCEL_RIDE: "cancel-search-ride",

  // REWARDS
  REQUEST_REWARD: "request-reward-list",
  REQUEST_REWARD_DETAILS: "request-reward-detail",
  REWARD_PAY: "reward-paid",
  REWARD_HISTORY: "reward-history",

  // PAY TAX
  PAY_TAX_LIST: "pay-tax-list",

  // BOOKING
  BOOKING_LIST: "bookings",
  BOOKING_VIEW: "booking-view/",

  // SCHEDULED BOOKING
  SCHEDULED_BOOKING: "pre-bookings",

  // REFFERALLS
  REFFERAL: "refferals",

  // CANCEL && REJECTERD  && MISSED OUT
  CANCEL_REJECT_MISSED: "cancel-ride",
  REJECT_RIDE_VIEW: "reject-ride-view/",
  CANCEL_RIDE_VIEW: "cancel-ride-view/",

  // REVIEW
  USER_REVIEW: "user-reviews",
  DRIVER_REVIEW: "driver-reviews",

  // ACTIVITY LOG
  ACTIVITY_LOG: "activity-log",

  // REPORTS
  REPORTS: "report",
  EXCEL: "export",

  // NOTIFICATION
  READ_STATUS: "notification-read-status",
  READ_ENTRY: "notification-read-entry",
  NOTIFY_LIST: "all-notifications",

  // PUSH NOTIFICATION
  PUSH_NOTIFY: "d-notification",
  PUSH_NOTIFYADD:"post-notification",
  PUSH_NOTIFYLIST:"notification-list",
  PUSHNOTIFYUSERLIST:"active-user-list",
  PUSHNOTIFYDRIVERLIST:"accepted-drivers",
  PUSHNOTIFYDELETE:"remove-notification",
  // PDF COMPLETED RIDE
  COMPLETED_PDF: "completed-rides-pdf",
  COMPLETED_RIDES: "completed-rides",

  // /RIDE TYPE
  RIDE_TYPE: "ride-type",

  // SETTING
  FARE_PRICE: "prices",
  FARE_PRICE_STORE: "price-store",
  FARE_PRICE_VIEW: "price-view/",
  FARE_PRICE_UPDATE: "price-update/",
  FARE_PRICE_DELETE: "price-delete/",

  CHARGE_LIST: "charges-list",
  CHARGE_STORE: "store-charges",
  CHARGE_VIEW: "charge-view/",
  CHARGE_UPDATE: "update-charge/",
  CHARGE_DELETE: "charge-delete/",
  CHARGE_STATUS: "charge-status/",

  // SUBSCRIPTION
  SUBSCRIPTION: "subscription-list",
  SUBSCRIPTION_ADD: "store-subscription",
  SUBSCRIPTION_EDIT: "update-subscription/",
  SUBSCRIPTION_VIEW: "subscription-view/",
  SUBSCRIPTION_DELETE: "delete-subscription/",
  SUBSCRIPTION_STATUS: "subscription-status/",

  // COUPONS
  COUPONS: "coupons-list",
  COUPONS_ADD: "store-coupons",
  COUPONS_EDIT: "update-coupon/",
  COUPONS_VIEW: "coupon-view/",
  COUPONS_DELETE: "coupon-delete/",

  // REWARDS
  REWARDS: "reward-list",
  REWARDS_ADD: "store-reward",
  REWARDS_EDIT: "update-reward/",
  REWARDS_VIEW: "reward-view/",
  REWARDS_DELETE: "reward-delete/",

  //FAQ
  FAQLIST:"faq-list",
  FAQADD:"faq-store",
  FAQVIEW:"faq-view",
  FAQUPDATE:"update-faq",
  FAQDELETE:"faq-delete",

  // Booking history
BOOKING_HISTORY_LIST:"user/booking-history",

// setting
ONEWAYLIST:"setting-list",
UPDATESETTING:"update-setting",
};



export const TOKEN = "token";
export const USER_DETAILS = "user_details";
export const PROFILE = "profile";
export const ERROR_STATUS = "error_status";
export const MAPS_API_KEY = "AIzaSyAiQyPFcrisIDlCqjPxWdnhxXPu1Ig1FHY";

export const EMAIL =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
