import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt, faEye } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Row, Col, Pagination, Button } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ReactPaginate from "react-paginate";
import Zone from "./zone";
import FareSetting from "./fareSetting";
import Tax from "./tax";
import Fare from "./fare";
import OtherCharge from "./otherCharge";
import Vechile from "./Vechile";
import WaitingFare from "./waiting";
import Settings from"./settings";
import {
  useChargeAddMutation,
  useChargeEditMutation,
  useLazyChargeDeleteQuery,
  useLazyChargeListQuery,
  useLazyChargeStatusQuery,
  useLazyChargeViewQuery,
} from "../../../constants/redux/api/api";
import Insurance from "./Insurance";
import TaxSettings from "./TaxSettings";

function Setting() {
  const [nightFare, setNightFare] = useState([]);

  // FOR CHARGES
  const [chargeListApi] = useLazyChargeListQuery();

  const getCharges = () => {
    chargeListApi()
      .unwrap()
      .then((res) => {
        setNightFare(res.charges);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getCharges();
  }, []);

  return (
    <>
      <aside>
        <TopHeader type="setting" />
        <article>
          <div className="p-3">
            <Tabs
              defaultActiveKey="faresetting"
              id="uncontrolled-tab-example"
              className="mb-3 tab-appoinment w-100"
            >
              <Tab eventKey="faresetting" title="Fare Setting">
                <FareSetting />
              </Tab>
              {/* <Tab eventKey="distance" title="Distance">
                <OtherCharge />
              </Tab>
              <Tab eventKey="margin" title="Margin">
                <Tax />
              </Tab>
              <Tab eventKey="vehicle" title="Vehicle">
                <Vechile />
              </Tab>
              <Tab eventKey="settings" title="settings">
                <Settings />
              </Tab>
              </Tab> */}
               <Tab eventKey="settings" title="settings">
                <Settings />
              </Tab>
              <Tab eventKey="fair" title="Night Fair">
                <WaitingFare nightFare={nightFare} getCharges={getCharges} />
              </Tab>
              <Tab eventKey="Tax" title="Tax">
                <TaxSettings/>
              </Tab>
              <Tab eventKey="Insurance" title="Insurance">
                <Insurance />
              </Tab>
            </Tabs>
          </div>
        </article>
      </aside>
    </>
  );
}

export default Setting;
