import React from "react";
import { Form } from "react-bootstrap";
import { useState } from "react";
import TopHeader from "../../../component/TopHeader";
import { bannerList, maleAvatar } from "../../../assets/img";
import { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ReactPaginate from "react-paginate";
import moment from "moment/moment";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
// import Pagination from "react-bootstrap/Pagination";
import { Row, Col, Pagination, Button } from "react-bootstrap";
import BTable from "react-bootstrap/Table";
import { CiSquareRemove } from "react-icons/ci";
import { IoIosAdd } from "react-icons/io";

import { GlobalFilter } from "../Roles/GlobalFilter";

import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";

import swal from "sweetalert";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import Swal from "sweetalert2";
import {
  useAddSubscriptionMutation,
  useCouponAddMutation,
  useCouponEditMutation,
  useLazyCouponDeleteQuery,
  useLazyCouponListQuery,
  useLazyCouponViewQuery,
  useLazyEditSubscriptionQuery,
  useUpdateSubscriptionMutation,
  useLazyPushuserlistQuery,
  useLazyPushdriverlistQuery

} from "../../../constants/redux/api/api";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      {/* show */}
      <Row className="mb-3 table-show row">
        <Col className="d-flex align-items-center">
          <p>Show</p>

          <select
            className="form-control w-auto mx-2 p-2 py-1"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
          <p>entries</p>
        </Col>
        <Col className="d-flex justify-content-end">
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          {/* <Link to="/support/add-support">
            <Button style={{ background: 'rgb(95,188,95)' }} className="btn-sm btn-round has-ripple ml-2 border-0">
              <i className="feather icon-plus" /> Add Ticket
            </Button>
          </Link> */}
        </Col>
      </Row>
      {/* show */}
      <BTable size="sm" striped bordered hover responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      {/* pagination */}
      <Row className="justify-content-between table-show my-2">
        <Col>
          <span className="d-flex align-items-center">
            <p className="px-1">Page</p>{" "}
            <strong>
              {" "}
              {pageIndex + 1} of {pageOptions.length}{" "}
            </strong>{" "}
            <p className="px-1">|</p>
            <p className="px-1">Go to page:</p>{" "}
            <input
              type="number"
              className="form-control ml-2  p-1 py-1 text-center"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "40px" }}
            />
          </span>
        </Col>
        <Col>
          <Pagination className="justify-content-end ">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
      {/*paginations  */}
    </>
  );
}

function AddCoupon() {
  const navigate = useNavigate();
  const location = useLocation();
  const Id = location?.state?.id;
  const type = location?.state?.type;

  const [input, setInput] = useState([]);
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [title, setTitle] = useState("");
  const [percentage, setPercentage] = useState("");
  const [types, setTypes] = useState("");
  const [value, setValue] = useState("");
  const [max_amount, setMax_amount] = useState("");
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");
  const [expires_at, setExpires_at] = useState("");
  const [user_id,setUser_id]=useState("");
  const [driver_id,setDriver_id]=useState("");
  const [description,setDescription]=useState("");
  const [conditions, setConditions] = useState([{ value: "" }]);
  const [btn, setBtn] = useState(false);
  const [user, setUser] = useState("");
  const [userList, setUserList] = useState([]);
  const [driverList, setDriverList] = useState([]);
  const [driver, setDriver] = useState("");

  const [userErr, setUserErr] = useState(false);

  const [nameErr, setNameErr] = useState(false);
  const [validityErr, setValidityErr] = useState(false);
  const [codeErr, setCodeErr] = useState(false);
  const [titleErr, setTitleErr] = useState(false);
  const [percentageErr, setPercentageErr] = useState(false);
  const [typesErr, setTypesErr] = useState(false);
  const [valueErr, setValueErr] = useState(false);
  const [max_amountErr, setMax_amountErr] = useState(false);
  const [start_dateErr, setStart_dateErr] = useState(false);
  const [end_dateErr, setEnd_dateErr] = useState(false);
  const [expires_atErr, setExpires_atErr] = useState(false);
  const [conditionsErr, setConditionsErr] = useState(false);

  //RTK
  const [couponListApi] = useLazyCouponListQuery();
  const [couponDeleteApi] = useLazyCouponDeleteQuery();
  const [couponViewApi] = useLazyCouponViewQuery();
  const [couponAddApi] = useCouponAddMutation();
  const [couponEditApi] = useCouponEditMutation();
  const [userlist]=useLazyPushuserlistQuery();
  const [driverAcceptApi] = useLazyPushdriverlistQuery();


  const addZone = () => {
    let count = 1;
    conditions?.map((item, ind) => {
      if (item.value) {
        count = 1;
      } else {
        count = count + 1;
      }
    });

    if (
      name?.length == 0 ||
      code?.length == 0 ||
      title?.length == 0 ||
      percentage?.length == 0 ||
      types?.length == 0 ||
      value?.length == 0 ||
      max_amount?.length == 0 ||
      start_date?.length == 0 ||
      end_date?.length == 0 ||
      expires_at?.length == 0 ||
      count !== 1||
      (!user_id && !driver_id)
    ) {
      setNameErr(true);
      setMax_amountErr(true);
      setValueErr(true);
      setTitleErr(true);
      setTypesErr(true);
      setPercentageErr(true);
      setCodeErr(true);
      setValidityErr(true);
      setStart_dateErr(true);
      setEnd_dateErr(true);
      setExpires_atErr(true);
      setConditionsErr(true);
    } else {
      let formData = new FormData();
      formData.append("name", name);
      formData.append("code", code);
      formData.append("title", title);
      formData.append("percentage", percentage);
      formData.append("type", types);
      formData.append("value", value);
      formData.append("max_amount", max_amount);
      formData.append("start_date", start_date);
      formData.append("end_date", end_date);
      formData.append("expires_at", expires_at);
      
      if (driver) {
        formData.append("driver_id", driver_id);
      }
      if (user) {
        formData.append("user_id", user_id);
      }
      if(description){
      formData.append("description",description)
      }
      

      conditions?.map((item, ind) => {
        formData.append(`conditions[${ind}]`, item.value);
      });

      if (Id) {
        setBtn(true);
        couponEditApi({ id: Id, payload: formData })
          .then((res) => {
            if (res?.data?.status == "success") {
              toast.success(res?.data?.message, {
                position: "top-right",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 2000,
              });
              setBtn(false);
              navigate("/coupon");
            } else {
              setBtn(false);
              toast.error(res?.data?.message, {
                position: "top-right",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 2000,
              });
            }
          })
          .catch((err) => {
            console.log("err", err);
            setBtn(false);
          });
      } else {
        setBtn(true);
        couponAddApi(formData)
          .unwrap()
          .then((res) => {
            if (res?.status == "success") {
              setBtn(false);
              toast.success(res?.message, {
                position: "top-right",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 2000,
              });
              navigate("/coupon");
            } else {
              setBtn(false);
              toast.error(res?.message, {
                position: "top-right",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 2000,
              });
            }
          })
          .catch((err) => {
            console.log("err", err);
            setBtn(false);
          });
      }
    }
  };
  const apiUser = () => {
    userlist()
      .unwrap()
      .then((res) => {
        console.log(res);
        setUserList(res?.active_users);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const apiDriver = () => {
    driverAcceptApi()
      .unwrap()
      .then((res) => {
        setDriverList(res?.drivers);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const view = () => {
    couponViewApi(Id)
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          let data = res?.coupon;
          setName(data?.name);
          setTitle(data?.title);
          setCode(data?.code);
          setPercentage(data?.percentage);
          setTypes(data?.type);
          setValue(data?.value);
          setMax_amount(data?.max_amount);
          if (data?.start_date) {
            setStart_date(moment(data?.start_date).format("YYYY-MM-DD"));
          }

          if (data?.end_date) {
            setEnd_date(moment(data?.end_date).format("YYYY-MM-DD"));
          }

          if (data?.expires_at) {
            setExpires_at(moment(data?.expires_at).format("YYYY-MM-DD"));
          }
          if (data?.conditions?.length > 0) {
            let temp = [];
            data?.conditions?.map((item, ind) => {
              temp.push({
                value: item,
              });
            });
            setConditions(temp);
          }
        } else {
          toast.error(res?.message, {
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            autoClose: 2000,
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (Id) {
      view();
    }
    apiUser();
    apiDriver();
  }, []);

  const addConditions = () => {
    let temp = [...conditions];
    let count = 1;
    temp.map((item, ind) => {
      if (item.value) {
        count = 1;
      } else {
        count = count + 1;
      }
    });

    if (count == 1) {
      setConditionsErr(false);
      temp.push({ value: "" });
      setConditions(temp);
    } else {
      setConditionsErr(true);
    }
  };

  const removeConditions = (ind) => {
    let temp = [...conditions];
    temp.splice(ind, 1);
    setConditions(temp);
  };

  const handleConditons = (event, ind) => {
    let temp = [...conditions];
    temp[ind]["value"] = event;
    setConditions(temp);
  };

  return (
    <aside>
      {type == "edit" ? (
        <TopHeader type="editCoupen" />
      ) : (
        <TopHeader type="addCoupen" />
      )}
      <article className="p-3">
        <div className="p-3">
          <div className="p-3 bg-white rounded-2">
            <Form className="d-flex flex-wrap">
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Name
                    </label>
                    <input
                      type="Name"
                      id="example-if-email"
                      name="name"
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Name"
                      value={name}
                    />
                    {name?.length == 0 && nameErr && (
                      <p className="err-p">Enter Name</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Code
                    </label>
                    <input
                      type="Name"
                      id="example-if-email"
                      name="name"
                      onChange={(e) => setCode(e.target.value)}
                      placeholder="Code"
                      value={code}
                    />
                    {code?.length == 0 && codeErr && (
                      <p className="err-p">Enter Code</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Title
                    </label>
                    <input
                      type="Name"
                      id="example-if-email"
                      name="name"
                      onChange={(e) => setTitle(e.target.value)}
                      placeholder="Title"
                      value={title}
                    />
                    {title?.length == 0 && titleErr && (
                      <p className="err-p">Enter Title</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Percentage
                    </label>
                    <input
                      type="Name"
                      id="example-if-email"
                      name="name"
                      onChange={(e) => setPercentage(e.target.value)}
                      placeholder="Percentage"
                      value={percentage}
                    />
                    {percentage?.length == 0 && percentageErr && (
                      <p className="err-p">Enter Percentage</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Type
                    </label>
                    <select
                      type="Name"
                      id="example-if-email"
                      name="name"
                      placeholder="Type"
                      onChange={(e) => setTypes(e.target.value)}
                      value={types}
                    >
                      <option value={""} disabled>
                        Select Type
                      </option>
                      <option value={1}>User Normal Coupon</option>
                      <option value={2}>Driver Normal Coupon</option>
                      <option value={3}>Reward Coupon</option>
                    </select>
                    {types?.length == 0 && typesErr && (
                      <p className="err-p">Select Type</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Value
                    </label>
                    <input
                      type="Name"
                      id="example-if-email"
                      name="name"
                      onChange={(e) => setValue(e.target.value)}
                      placeholder="Value"
                      value={value}
                    />
                    {value?.length == 0 && valueErr && (
                      <p className="err-p">Enter Value</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Max.Amount
                    </label>
                    <input
                      type="Name"
                      id="example-if-email"
                      name="Offer"
                      onChange={(e) => setMax_amount(e.target.value)}
                      placeholder="Max.Amount"
                      value={max_amount}
                    />
                    {max_amount?.length == 0 && max_amountErr && (
                      <p className="err-p">Enter Max.Amount</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Start Date
                    </label>
                    <input
                      type="date"
                      id="example-if-email"
                      name="Offer"
                      onChange={(e) => setStart_date(e.target.value)}
                      placeholder="Start Date"
                      value={start_date}
                    />
                    {start_date?.length == 0 && start_dateErr && (
                      <p className="err-p">Enter Start Date</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      End Date
                    </label>
                    <input
                      type="date"
                      id="example-if-email"
                      name="Offer"
                      onChange={(e) => setEnd_date(e.target.value)}
                      placeholder="End Date"
                      value={end_date}
                    />
                    {end_date?.length == 0 && end_dateErr && (
                      <p className="err-p">Enter End Date</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Expires At
                    </label>
                    <input
                      type="date"
                      id="example-if-email"
                      name="Offer"
                      onChange={(e) => setExpires_at(e.target.value)}
                      placeholder="Expires At"
                      value={expires_at}
                    />
                    {expires_at?.length == 0 && expires_atErr && (
                      <p className="err-p">Enter Expires At</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                    User Id
                    </label>
                    <select
                name="type"
                onChange={(e) => setUser_id(e.target.value)}
                value={user_id}
              >
                <option selected>select type</option>
                {userList?.map((item) => {
                  console.log(item,"newitemS");
                  
                  return <option value={item?.id}>{item?.name}</option>;
                })}
              </select>
              {/* {user?.length == "" && userErr && (
                <p className="err-p">Enter User </p>
              )} */}
                    {/* {expires_at?.length == 0 && expires_atErr && (
                      <p className="err-p">Enter Expires At</p>
                    )} */}
                  </div>
                </div>
              </div>

              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                    Driver Id
                    </label>
                    <select
                name="type"
                onChange={(e) => setDriver_id(e.target.value)}
                value={driver_id}
              >
                <option value={""}>select user</option>
                {driverList?.map((item, ind) => {
                  return <option value={item?.id}>{item?.name}</option>;
                })}
              </select>
              
                    {/* {expires_at?.length == 0 && expires_atErr && (
                      <p className="err-p">Enter Expires At</p>
                    )} */}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                    Description
                    </label>
                    <input
                      type="Name"
                      id="example-if-email"
                      name="Offer"
                      onChange={(e) => setDescription(e.target.value)}
                      placeholder="Description"
                      value={description}
                    />
                    {/* {expires_at?.length == 0 && expires_atErr && (
                      <p className="err-p">Enter Expires At</p>
                    )} */}
                  </div>
                </div>
              </div>
              {conditions?.map((item, ind) => {
                return (
                  <div
                    className="form-group col-12 col-sm-6 col-lg-6 ps-0"
                    key={ind}
                  >
                    <div className="form-check ps-0">
                      <div className="px-1 col-sm-12 modal-label-input">
                        <div className="d-flex">
                          <label className="text-primary-dark" for="">
                            Conditions
                          </label>
                          {ind == 0 && (
                            <div
                              role={"button"}
                              onClick={() => addConditions()}
                            >
                              <IoIosAdd fontSize={24} />
                            </div>
                          )}
                        </div>
                        <div className="d-flex">
                          <input
                            type="text"
                            id="example-if-email"
                            name="Offer"
                            onChange={(e) =>
                              handleConditons(e.target.value, ind)
                            }
                            placeholder="Conditions"
                            value={item.value}
                          />
                          {ind !== 0 && (
                            <div
                              role={"button"}
                              onClick={() => removeConditions(ind)}
                            >
                              <CiSquareRemove fontSize={24} />
                            </div>
                          )}
                        </div>
                        {item.value?.length == 0 && conditionsErr && (
                          <p className="err-p">Enter Conditions</p>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}

              <div className="mt-4 ms-auto">
                <button
                  type="button"
                  className={`comn-btn bg-success-gradient px-3 py-2 me-2 ${
                    btn ? "opacity-50" : ""
                  }`}
                  onClick={addZone}
                  disabled={btn ? true : false}
                >
                  Submit
                </button>
              </div>
            </Form>
          </div>
        </div>
      </article>
    </aside>
  );
}

export default AddCoupon;
