import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Tab, Tabs } from "react-bootstrap";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import ShareRevenue from "./ShareRevenue";

const RevenueList = () => {
  const [data, setData] = useState();
  return (
    <aside>
      <TopHeader type="revenues" />

      <div className="p-3">
        <ShareRevenue />
      </div>
    </aside>
  );
};

export default RevenueList;
