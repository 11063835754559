import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt, faTrash } from "@fortawesome/free-solid-svg-icons";

import { Row, Col, Pagination, Button } from "react-bootstrap";
import BTable from "react-bootstrap/Table";

import { GlobalFilter } from "../Roles/GlobalFilter";

import {
    useTable,
    useSortBy,
    usePagination,
    useGlobalFilter,
} from "react-table";


function Table({ columns, data }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,

        globalFilter,
        setGlobalFilter,

        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 10 },
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    return (
        <>
            <BTable size="sm" striped bordered hover responsive {...getTableProps()}>
                <thead className="text-center">
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                // Add the sorting props to control sorting. For this example
                                // we can add them into the header props
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render("Header")}
                                    {/* Add a sort direction indicator */}
                                    <span>
                                        {column.isSorted ? (
                                            column.isSortedDesc ? (
                                                <span className="feather icon-arrow-down text-muted float-right" />
                                            ) : (
                                                <span className="feather icon-arrow-up text-muted float-right" />
                                            )
                                        ) : (
                                            ""
                                        )}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()} className="text-center">
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return (
                                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </BTable>
            <Row className="justify-content-between table-show my-2">
                <Col>
                    <span className="d-flex align-items-center">
                        <p className="px-1">Page</p>{" "}
                        <strong>
                            {" "}
                            {pageIndex + 1} of {pageOptions.length}{" "}
                        </strong>{" "}
                        <p className="px-1">|</p>
                        <p className="px-1">Go to page:</p>{" "}
                        <input
                            type="number"
                            className="form-control ml-2  p-1 py-1 text-center"
                            defaultValue={pageIndex + 1}
                            onChange={(e) => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                gotoPage(page);
                            }}
                            style={{ width: "40px" }}
                        />
                    </span>
                </Col>
                <Col>
                    <Pagination className="justify-content-end ">
                        <Pagination.First
                            onClick={() => gotoPage(0)}
                            disabled={!canPreviousPage}
                        />
                        <Pagination.Prev
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                        />
                        <Pagination.Next
                            onClick={() => nextPage()}
                            disabled={!canNextPage}
                        />
                        <Pagination.Last
                            onClick={() => gotoPage(pageCount - 1)}
                            disabled={!canNextPage}
                        />
                    </Pagination>
                </Col>
            </Row>
        </>
    );
}
const TaxSettings = () => {
    const navigate = useNavigate();
    const permissons = JSON.parse(localStorage.getItem("role"));
    const [list, setList] = useState([]);
    const [popup, setPopup] = useState(false);
    const [field, setField] = useState();



    const columns = React.useMemo(
        () => [
            {
                Header: "S.No",
                accessor: "sno",
                Cell: ({ value, row }) => {
                    return <>{row.index + 1}</>;
                },
            },
            {
                Header: "Tax",
                accessor: "type",
                Cell: ({ value, row }) => {
                    return <span>{value}</span>;
                },
            },

            {
                Header: "Action",
                accessor: "action",
                Cell: ({ value, row }) => {
                    return (
                        <React.Fragment>
                            <div className="flex-ac-tr">
                                <button
                                    className="comn-btn bg-primary edit-btn-svg"
                                    // onClick={() => view(row.original.id)}
                                    onClick={setPopup(true)}
                                >
                                    <FontAwesomeIcon icon={faPen} />
                                </button>
                                <button
                                    className="comn-btn bg-primary edit-btn-svg"
                                    // onClick={() => deleteHandle(row.original.id)}
                                    onClick={() => setList([])}
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                </button>
                            </div>
                        </React.Fragment>
                    );
                },
            },
        ],
        []
    );
    useEffect(() => {
        setList(
            [{
                sno: "1",
                type: "type",
            }
            ]
        )
    }, [])

    //     const submitHandle = () => {
    //         if (files?.length==0){
    //             btn(true)
    //         }
    // }




    return (
        <aside>
            {/* {popup &&
                <div className='bg-white d-flex ac-jc  w-100'>
                    <div className='d-flex ac-jc'>
                        <p>Insurance</p>
                        <input className='' type='text' value={field} />
                    </div>
                    <button className='comn-btn bg-primary1'
                    >
                        submit
                    </button>
                </div>
            } */}
            <article className="p-3">
                <div className="p-2 bg-white rounded-2">
                    <div className="ad-btn w-100 flex-ac-jb p-2 d-flex justify-content-end">
                        <div className="flex-ac-jc">
                            {list?.length == 0 && <button
                                onClick={() => setPopup(!popup)}
                                className="comn-btn bg-primary1 px-3 py-2"
                            >
                                Add Margin
                            </button>}
                        </div>
                    </div>
                    <Table columns={columns} data={list} />
                </div>
            </article>
        </aside>
    )
}

export default TaxSettings